<!--
  * @Descripttion: 星球详情
  * @version:
  * @Author: GxG
  * @Date: 2023-03-16 11:11:11
 * @LastEditors: Toby toby@gmail.com
 * @LastEditTime: 2023-09-22 21:28:56
-->
<template>
  <div
    :class="[
      inOutAnimation
        ? 'stardetails-main-box fade-into-back'
        : 'stardetails-main-box fade-out-back',
      plantInfo.eraId ? 'eraId-type' + plantInfo.eraId : '',
    ]"
  >
    <div class="stardetails-bg">
      <div
        class="stardetails-center"
        :style="{ height: lang == 'en_US' ? '700px' : '680px' }"
      >
        <!-- 关闭 -->
        <div class="stardetails-colse-box flex-y-center" @click="closeFun(0)">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="21.1822"
              height="1.24601"
              transform="matrix(0.701962 -0.712215 0.701962 0.712215 0.256226 15.0863)"
              fill="white"
            />
            <rect
              width="21.1822"
              height="1.24601"
              transform="matrix(0.701962 0.712215 -0.701962 0.712215 0.874634 0.0262756)"
              fill="white"
            />
          </svg>
        </div>
        <!-- 图片 -->
        <div
          :class="
            inOutAnimation
              ? 'stardetails-content-ce fade-into-img'
              : 'stardetails-content-ce fade-out-img'
          "
        >
          <img
            v-if="plantInfo.eraId == 6"
            src="../public/images/star/h5/ct-1.png"
          />
          <img
            v-if="plantInfo.eraId == 2"
            src="../public/images/star/h5/ct-2.png"
          />
          <img
            v-if="plantInfo.eraId == 3"
            src="../public/images/star/h5/ct-3.png"
          />
          <img
            v-if="plantInfo.eraId == 4"
            src="../public/images/star/h5/ct-4.png"
          />
          <img
            v-if="plantInfo.eraId == 5"
            src="../public/images/star/h5/ct-5.png"
          />
          <img
            v-if="plantInfo.eraId == 1"
            src="../public/images/star/h5/ct-6.png"
          />
          <img
            v-if="plantInfo.eraId == 7"
            src="../public/images/star/h5/ct-7.png"
          />
        </div>
        <!-- 内容 -->
        <div class="stardetails-content">
          <!-- 详情 -->
          <div
            :class="
              inOutAnimation
                ? 'stardetails-content-le fade-into-left'
                : 'stardetails-content-le fade-out-left'
            "
          >
            <div
              class="tardetails-content-le-title"
              :style="{ fontSize: lang == 'en_US' ? '30px' : '38px' }"
            >
              {{ planetInfo.eraName }}
            </div>
            <div
              class="tardetails-content-le-list-box w100 flex-x-start"
              :class="[plantInfo.eraId ? 'tardetailsbg' + plantInfo.eraId : '']"
            >
              <div
                class="tardetails-content-le-list-le"
                v-for="(item, index) in starDetailsList"
                :key="index"
              >
                <div class="tardetails-content-le-list-title">
                  {{ item.title }}
                </div>
                <div class="tardetails-content-le-list-texts">
                  {{ item.text }}
                </div>
              </div>
              <div
                class="pool-img"
                :class="[
                  inOutAnimation
                    ? 'animate__animated animate__zoomIn'
                    : 'animate__animated animate__zoomOut',
                ]"
              >
                <img
                  v-if="plantInfo.eraId == 6"
                  src="../public/images/pool/poolimg1.png"
                />
                <img
                  v-if="plantInfo.eraId == 2"
                  src="../public/images/pool/poolimg2.png"
                />
                <img
                  v-if="plantInfo.eraId == 3"
                  src="../public/images/pool/poolimg3.png"
                />
                <img
                  v-if="plantInfo.eraId == 4"
                  src="../public/images/pool/poolimg4.png"
                />
                <img
                  v-if="plantInfo.eraId == 5"
                  src="../public/images/pool/poolimg5.png"
                />
                <img
                  v-if="plantInfo.eraId == 1"
                  src="../public/images/pool/poolimg6.png"
                />
                <img
                  v-if="plantInfo.eraId == 7"
                  src="../public/images/pool/poolimg7.png"
                />
              </div>
            </div>
          </div>
          <!-- 购买土地 -->
          <div
            v-if="plantInfo.pageType == 'buy'"
            :class="
              inOutAnimation
                ? 'stardetails-content-ri fade-into-ri'
                : 'stardetails-content-ri fade-out-ri'
            "
          >
            <div class="stardetails-content-ri-title">
              {{ $t("pool.desc19") }}
            </div>
            <div class="stardetails-content-ri-list-box flex-x-start">
              <div
                :class="
                  item.isActive
                    ? 'stardetails-content-ri-list stardetails-content-ri-list-acetive'
                    : 'stardetails-content-ri-list'
                "
                v-for="(item, index) in massifList"
                :key="index"
                @click="
                  selcetfun('massifList', 'isActive', index);
                  priceCalculation(item.number, item.price);
                "
              >
                {{ item.title }}
              </div>
            </div>
            <div class="stardetails-tips">{{ $t("pool.desc103") }}</div>
            <div class="stardetails-icon">
              <el-dropdown @command="handleCommand1" trigger="click">
                <span class="el-dropdown-link">
                  <div class="icon-name flex">
                    <img
                      :src="
                        planetInfo.eraIcon == undefined
                          ? require('../public/images/logoimg.png')
                          : planetInfo.eraIcon
                      "
                      alt=""
                    />{{ planetInfo.eraName }}
                  </div>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      :command="item.eraId"
                      v-for="item in startList"
                    >
                      <div class="coin-info flex">
                        <img :src="item.eraIcon" alt="" />{{ item.eraName }}
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
            <div class="stardetails-tips">{{ $t("pool.desc66") }}</div>
            <div class="stardetails-icon">
              <el-dropdown @command="handleCommand" trigger="click">
                <span class="el-dropdown-link">
                  <div class="icon-name flex">
                    <img
                      :src="
                        iconInfo.icon == undefined
                          ? require('../public/images/logoimg.png')
                          : iconInfo.icon
                      "
                      alt=""
                    />{{ iconInfo.coinSymbol }}
                    <p>{{ discountRate }} %</p>
                  </div>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      :command="item.coinId"
                      v-for="item in planetInfo.coinList"
                    >
                      <div class="coin-info flex">
                        <img :src="item.icon" alt="" />{{ item.coinSymbol }}
                        <p>{{ parseFloat(item.discountRate) * 100 }}%</p>
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
            <div class="stardetails-price flex">
              <div>
                {{ $t("pool.desc67", { n1: iconInfo.coinSymbol })
                }}<span>{{ assetsInfo.balance }}</span>
              </div>
              <p>
                {{ iconInfo.coinSymbol }}：<span>{{
                  common.getnum(iconMoney)
                }}</span>
              </p>
            </div>
            <div class="stardetails-content-ri-price flex-x-start">
              <div class="stardetails-content-ri-price-le">
                <div class="stardetails-content-ri-price-le-title">
                  {{ $t("pool.desc23") }}
                </div>
                <div class="stardetails-content-ri-price-le-calc flex-x-start">
                  <div
                    :class="
                      isCustomPrice && massifnumber > 1
                        ? 'stardetails-content-ri-price-sub price flex-y-center'
                        : 'stardetails-content-ri-price-sub flex-y-center'
                    "
                    @click="customQuantity(2)"
                  >
                    <svg
                      width="12"
                      height="2"
                      viewBox="0 0 12 2"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect opacity="0.2" width="12" height="2" fill="white" />
                    </svg>
                  </div>
                  <div class="stardetails-content-ri-price-viw">
                    <span v-if="!isCustomPrice">{{ massifnumber }}</span>
                    <input
                      type="tel"
                      v-model="massifnumber"
                      @change="onChange"
                      v-else
                    />
                  </div>
                  <div
                    :class="
                      isCustomPrice
                        ? 'stardetails-content-ri-price-add price flex-y-center'
                        : 'stardetails-content-ri-price-add flex-y-center'
                    "
                    @click="customQuantity(1)"
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.2"
                        y="5"
                        width="12"
                        height="2"
                        fill="white"
                      />
                      <rect
                        opacity="0.2"
                        x="7"
                        width="12"
                        height="2"
                        transform="rotate(90 7 0)"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="stardetails-content-ri-price-ce"></div>
              <div class="stardetails-content-ri-price-ri">
                <div class="stardetails-content-ri-price-le-title">
                  {{ $t("pool.desc24") }}
                </div>
                <div class="stardetails-content-ri-price-ri-sum">
                  {{ priceSum }} USDT
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 地球 -->
        <div
          :class="
            inOutAnimation
              ? 'plaent-box fade-into-star'
              : 'plaent-box fade-out-star'
          "
        ></div>
        <!-- 按钮 -->
        <div
          v-if="plantInfo.pageType == 'buy'"
          class="stardetails-content-ri-but"
          :class="[
            inOutAnimation
              ? 'animate__animated animate__bounceInUp'
              : 'animate__animated animate__bounceOutDown',
          ]"
          @click="handlerSubmit"
        >
          {{ $t("pool.desc25") }}
        </div>
      </div>
    </div>
    <!-- 购买 -->
    <el-dialog
      title="提示"
      v-model="dialogBackset"
      width="30%"
      :append-to-body="true"
      class="modalinfo"
      :modal="false"
    >
      <div class="modalinfo-con">
        <div class="modalinfo-con-title">
          {{ $t("planet.desc29") }}
          <img
            src="../public/images/closemodal.png"
            alt=""
            @click="dialogBackset = false"
          />
        </div>
        <div
          class="mining-tips"
          v-html="$t('planet.desc30', { n1: `${priceSum} USDT` })"
        ></div>
        <div class="mining-projuct flex">
          <img :src="planetInfo.eraIcon" alt="" />
          <div class="projuct-info">
            <div>{{ planetInfo.eraName }}</div>
            <p>X{{ massifnumber }}</p>
          </div>
        </div>
        <div class="modalinfo-con-address">
          <div class="address-name">{{ $t("home.desc7") }}</div>
          <div class="address-input">
            <input
              type="password"
              onKeyUp="value=value.replace(/[\W]/g,'')"
              v-model="password"
              :placeholder="$t('planet.desc17')"
            />
          </div>
        </div>
        <div class="modalinfo-con-address margintop">
          <div class="address-name">{{ $t("home.desc8") }}</div>
          <div class="address-input">
            <input
              type="tel"
              v-model="googleCode"
              onkeyup="this.value=this.value.replace(/\D/g,'')"
              onafterpaste="this.value=this.value.replace(/\D/g,'')"
              :placeholder="$t('planet.desc18')"
            />
          </div>
        </div>
        <div class="modalinfo-con-btn flex">
          <div class="flexcenter" @click="dialogBackset = false">
            {{ $t("planet.desc31") }}
          </div>
          <div class="flexcenter" @click="handlerPay" v-loading="loading">
            {{ $t("planet.desc32") }}
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 成功弹窗 -->
    <div class="buy-successmodal" v-if="showSuccess">
      <div class="buy-success">
        <img
          src="../public/images/closemodal.png"
          alt=""
          class="close-icon"
          @click="handlerClose"
        />
        <div class="buy-success-img">
          <img src="../public/images/successicon.png" alt="" />
        </div>
        <div class="buy-success-name">{{ $t("planet.desc50") }}</div>
        <div class="buy-success-desc">
          {{
            $t("planet.desc51", { n1: planetInfo.eraName, n2: massifnumber })
          }}
        </div>
        <div class="buy-success-btn flex">
          <div class="flexcenter" @click="$router.push('/planet')">
            {{ $t("planet.desc52") }}
          </div>
          <div class="flexcenter" @click="handlerClose">
            {{ $t("pool.desc104") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  toRefs,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  watchEffect,
  computed,
} from "vue";
import i18n from "@/public/lang/i18n";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import common from "@/utils/common";
import bus from "@/utils/bus";
export default {
  name: "stardetails",
  props: ["id", "callback", "eraId", "pageType", "planetId"],
  setup(props) {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const state = reactive({
      iconMoney: 0,
      planetInfo: {},
      showSuccess: false,
      dialogBackset: false,
      starDetailsList: null,
      googleCode: "",
      password: "",
      loading: false,
      massifList: [
        // {title:'99 daot 买1块', isActive:true, number:1, price: 99},
        // {title:'888 daot 买10块', isActive:false, number:10, price: 888},
        // {title:'4500 daot 买50块', isActive:false, number:50, price: 4500},
        // {title:'7500 daot 买100块', isActive:false, number:100, price: 7500},
        // {title:'此星球剩余全部土地', isActive:false, number:10, price: 888},
        // {title:'自定义数量', isActive:false},
      ],
      massifImg: "", //土地图片
      massifnumber: "", //土地数量
      price: "", //单价
      priceSum: "", //总价
      isCustomPrice: false, //自定义价格
      inOutAnimation: true, //进入关闭动画
      iconInfo: {},
      assetsInfo: {},
      lang: "zh_CN",
      discountRate: 0,
      plantInfo: {},
      startList: [],
    });
    // *********************************************************************************************************************************************************** //

    //弹窗
    const handlerSubmit = () => {
      if (!store.state.userData.hasSetPassword) {
        ElMessage.error(i18n.global.t("home.desc48"));
        bus.emit("hadleropen", 1);
        return;
      }
      if (!store.state.userData.hasBindGoogleAuth) {
        ElMessage.error(i18n.global.t("home.desc49"));
        bus.emit("hadleropen", 2);
        return;
      }
      if (state.iconMoney > parseFloat(state.assetsInfo.balance)) {
        ElMessage.error(
          state.iconInfo.coinSymbol + i18n.global.t("pool.desc68")
        );
        return;
      }
      if (state.planetInfo.sales > 0) {
        state.dialogBackset = true;
      }
    };
    const handlerPay = () => {
      if (state.password == "") {
        ElMessage.error(i18n.global.t("planet.desc17"));

        return;
      }
      if (state.googleCode == "") {
        ElMessage.error(i18n.global.t("planet.desc18"));
        return;
      }
      if (!state.loading) {
        state.loading = true;
        proxy
          .$post(proxy.URL.planet.buy, {
            eraId: state.planetInfo.eraId,
            num: state.massifnumber,
            password: common.mdpassword(state.password),
            googleCode: state.googleCode,
            coinId: state.iconInfo.coinId,
          })
          .then((res) => {
            state.loading = false;
            if (res.code == 0) {
              state.showSuccess = true;
              state.dialogBackset = false;
            } else {
              ElMessage.error(res.message);
            }
          });
      }
    };
    // 选择
    /**
     * @params {array} String 自定义参数： 数组名称
     * @params {vars}  String 自定义参数： 对象内属性
     * @params {index} Number 自定义参数： 数组下标
     */
    const selcetfun = function (array, vars, index) {
      if (typeof array == "string") {
        state[array].forEach((element, jindex) => {
          if (jindex == index) {
            element[vars] = true;
          } else {
            element[vars] = false;
          }
        });
      }
    };

    const alerts = function (name) {
      alert(name);
    };

    // 关闭按钮
    /**
     * @params {t} Number 自定义参数： t==0 关闭
     */
    const closeFun = function (t) {
      if (typeof t != "number") return;
      if (window.innerWidth > 1200) {
        //计算视口宽度是否在执行动画
        state.inOutAnimation = false;
        setTimeout(() => {
          props.callback(t);
        }, 800);
      } else {
        props.callback(t);
      }
    };

    // 购买土地价格计算
    /**
     * @params {number} Number 自定义参数： 数量
     * @params {price} Number 自定义参数： 数量
     */
    const priceCalculation = function (number, price) {
      if (typeof number == "number") {
        state.isCustomPrice = false;
        state.priceSum = number * price;
        state.massifnumber = number;
        state.iconMoney =
          parseFloat(state.iconInfo.coinAmount) *
          number *
          parseFloat(state.iconInfo.discountRate);
      } else {
        state.isCustomPrice = true;
        state.massifnumber = 1;
        state.priceSum = state.massifnumber * state.price;
        state.iconMoney =
          parseFloat(state.iconInfo.coinAmount) *
          parseFloat(state.iconInfo.discountRate);
      }
    };

    // 自定义数量
    /**
     * @params {type} Number 自定义参数： 1加， 2减
     * @params {price} Number 自定义参数： 数量
     */
    const customQuantity = function (type) {
      if (state.massifnumber > 0 && state.isCustomPrice) {
        if (
          type == 1 &&
          state.massifnumber < parseFloat(state.planetInfo.sales)
        ) {
          state.massifnumber += 1;
        } else if (state.massifnumber > 0) {
          state.massifnumber -= 1;
        }
        state.massifnumber == 0 ? (state.massifnumber = 1) : state.massifnumber;
        state.priceSum = state.massifnumber * state.price;
        state.iconMoney =
          parseFloat(state.iconInfo.coinAmount) *
          state.massifnumber *
          parseFloat(state.iconInfo.discountRate);
      }
    };
    //输入
    const onChange = (val) => {
      if (state.massifnumber > parseFloat(state.planetInfo.sales)) {
        state.massifnumber = parseFloat(state.planetInfo.sales);
      } else if (state.massifnumber < 1) {
        state.massifnumber = 1;
      }
      state.priceSum = state.massifnumber * state.price;
      state.iconMoney =
        parseFloat(state.iconInfo.coinAmount) *
        state.massifnumber *
        parseFloat(state.iconInfo.discountRate);
    };
    // *********************************************************************************************************************************************************** //
    const handleCommand1 = (val) => {
      state.planetInfo.eraId = val;
      state.plantInfo.eraId = val;
      state.planetInfo = [];
      state.iconInfo = {};
      state.starDetailsList = [];
      state.massifList = [];
      proxy.$post(proxy.URL.planet.list, {}).then((res) => {
        if (val) {
          let array = res.data;
          state.startList = res.data;
          for (let i = 0; i < array.length; i++) {
            if (array[i].eraId == val) {
              dealInfo(res.data[i]);
              break;
            }
          }
        }
      });
    };
    const dealInfo = function (item) {
      item.sales = parseFloat(item.totalLand) - parseFloat(item.saleCountLand);
      state.planetInfo = item;
      state.iconInfo = item.coinList[0];
      state.discountRate = parseFloat(state.iconInfo.discountRate) * 100;
      state.iconMoney =
        state.iconInfo.coinAmount * parseFloat(state.iconInfo.discountRate);
      state.starDetailsList = [
        { title: i18n.global.t("pool.desc14"), text: item.outCoinFullName },
        {
          title: i18n.global.t("planet.desc12"),
          text: item.pledgingLandCount || 0,
        },
        {
          title: i18n.global.t("pool.desc105"),
          text: `${item.priceAmount} USDT` || "--",
        },
        {
          title: i18n.global.t("pool.desc106"),
          text: item.noChangeLandCount || 0,
        },
      ];

      let amount = item.priceAmount,
        amount10 = 10 * parseFloat(item.priceAmount),
        amount50 = 50 * parseFloat(item.priceAmount),
        amount100 = 100 * parseFloat(item.priceAmount),
        salePrice = item.sales * parseFloat(item.priceAmount);

      if (localStorage.getItem("language") == "en_US") {
        state.massifList = [
          {
            title: i18n.global.t("pool.desc92", {
              n1: amount,
              n2: item.coinSymbol,
              n3: 1,
            }),
            isActive: true,
            number: 1,
            price: amount,
          },
          {
            title: i18n.global.t("pool.desc92", {
              n1: amount10,
              n2: item.coinSymbol,
              n3: 10,
            }),
            isActive: false,
            number: 10,
            price: amount10,
          },
          // { title: i18n.global.t('pool.desc92', { n1: amount50, n2: item.coinSymbol, n3: 50 }), isActive: false, number: 50, price: amount50 },
          // { title: i18n.global.t('pool.desc92', { n1: amount100, n2: item.coinSymbol, n3: 100 }), isActive: false, number: 100, price: amount100 },
          {
            title: i18n.global.t("pool.desc93"),
            isActive: false,
            number: item.sales,
            price: salePrice,
          },
          { title: i18n.global.t("pool.desc22"), isActive: false },
        ];
      } else {
        state.massifList = [
          {
            title: i18n.global.t("pool.desc20", {
              n1: amount,
              n2: item.coinSymbol,
              n3: 1,
            }),
            isActive: true,
            number: 1,
            price: amount,
          },
          {
            title: i18n.global.t("pool.desc20", {
              n1: amount10,
              n2: item.coinSymbol,
              n3: 10,
            }),
            isActive: false,
            number: 10,
            price: amount10,
          },
          // { title: i18n.global.t('pool.desc20', { n1: amount50, n2: item.coinSymbol, n3: 50 }), isActive: false, number: 50, price: amount50 },
          // { title: i18n.global.t('pool.desc20', { n1: amount100, n2: item.coinSymbol, n3: 100 }), isActive: false, number: 100, price: amount100 },
          {
            title: i18n.global.t("pool.desc21"),
            isActive: false,
            number: item.sales,
            price: salePrice,
          },
          { title: i18n.global.t("pool.desc22"), isActive: false },
        ];
      }
      state.price = item.priceAmount;
      priceCalculation(1, item.priceAmount);
      userMoney();
      setTimeout(() => {
        state.massifImg = item.eraIcon;
      }, 50);
    };
    // **                                                                接口方法                                                                                ** //
    // *********************************************************************************************************************************************************** //
    // 获取详情列表
    const getDetails = function () {
      proxy
        .$post(proxy.URL.star.getDetails, {
          planetId: state.plantInfo.planetId,
        })
        .then((res) => {
          if (res.code == 0) {
            state.plantInfo.eraId = res.data.eraId;
            dealInfo(res.data);
          } else {
            ElMessage.error(res.message);
          }
        });
    };
    const getStarList = function () {
      proxy.$post(proxy.URL.planet.list, {}).then((res) => {
        if (state.plantInfo.eraId) {
          let array = res.data;
          state.startList = res.data;
          for (let i = 0; i < array.length; i++) {
            if (array[i].eraId == state.plantInfo.eraId) {
              dealInfo(res.data[i]);
              break;
            }
          }
        }
      });
    };
    // *********************************************************************************************************************************************************** //
    // **                                                              路由器执行逻辑                                                                             ** //
    // *********************************************************************************************************************************************************** //
    // *********************************************************************************************************************************************************** //
    // **                                                           stup执行完执行下列方法                                                                         ** //
    // *********************************************************************************************************************************************************** //
    const handleCommand = (val) => {
      let info = state.planetInfo.coinList.find((item) => item.coinId == val);
      state.iconInfo = info;
      state.discountRate = parseFloat(info.discountRate) * 100;
      state.iconMoney =
        parseFloat(info.coinAmount) *
        state.massifnumber *
        parseFloat(state.iconInfo.discountRate);
      userMoney();
    };

    const userMoney = (val) => {
      proxy
        .$post(proxy.URL.assets.view, {
          coinId: state.iconInfo.coinId,
        })
        .then((res) => {
          state.assetsInfo = res.data;
        });
    };
    const handlerClose = () => {
      handleCommand1(state.planetInfo.eraId);
      state.showSuccess = false;
    };
    onMounted(() => {
      state.lang = localStorage.getItem("language");
      state.plantInfo.id = props.id;
      state.plantInfo.eraId = props.eraId;
      state.plantInfo.pageType = props.pageType;
      state.plantInfo.planetId = props.planetId;

      props.id == undefined ? getDetails() : getStarList();
    });

    return {
      customQuantity,
      priceCalculation,
      closeFun,
      alerts,
      selcetfun,
      onChange,
      handlerSubmit,
      handlerPay,
      handleCommand,
      handleCommand1,
      userMoney,
      handlerClose,
      ...toRefs(state),
    };
  },
};
</script>
<style scoped lang="less">
@import "../views/home/global.css";

.buy-successmodal {
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: url("../public/images/modalbg.png") center no-repeat;
  background-size: 100% 100%;
}

.buy-success {
  position: relative;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 365px;
  padding: 40px 0;
  background: linear-gradient(
    180deg,
    rgba(255, 226, 127, 0.3) -10.71%,
    rgba(110, 85, 38, 0.3) 84.58%
  );
  z-index: 56;
  text-align: center;
  border-radius: 16px;
  transform: translate(-50%, -50%);

  .close-icon {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  .buy-success-img {
    img {
      width: 64px;
      height: 64px;
    }
  }

  .buy-success-name {
    padding: 40px 0 24px;
    font-size: 24px;
    color: #ffffff;
  }

  .buy-success-desc {
    padding: 0 45px 24px;
    font-size: 16px;
    color: #a8a8a8;
    line-height: 28px;
  }

  .buy-success-btn {
    width: 365px;
    height: 63px;
    margin: 0 auto;
    justify-content: center;

    div {
      flex: 0 0 139px;
      height: 63px;
      background: url("../public/images/buymore.png") center no-repeat;
      background-size: 100% 100%;
      font-size: 16px;
      color: #000000;
      cursor: pointer;

      &:first-child {
        margin-right: 10px;
        background: url("../public/images/pool/mobilebtn.png") center no-repeat;
        background-size: 100% 100%;
        color: #fff;
      }
    }
  }
}

.stardetails-main-box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 96px;
  left: 0;
  z-index: 99;
  background: #000000;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px; //修改滚动条宽度
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #666666;
  }
}

.stardetails-bg {
  width: 100%;
  padding-bottom: 120px;
  padding-top: 21px;
  background-color: #000;
  background-image: url("../public/images/star/bg-6.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-items: flex-start;
  /* filter: blur(200px); */
}

.eraId-type2 .stardetails-bg {
  background-image: url("../public/images/star/bg-2.png");
}

.eraId-type3 .stardetails-bg {
  background-image: url("../public/images/star/bg-3.png");
}

.eraId-type4 .stardetails-bg {
  background-image: url("../public/images/star/bg-4.png");
}

.eraId-type5 .stardetails-bg {
  background-image: url("../public/images/star/bg-5.png");
}

.eraId-type6 .stardetails-bg {
  background-image: url("../public/images/star/bg-1.png");
}

.eraId-type7 .stardetails-bg {
  background-image: url("../public/images/star/bg-7.png");
}

.stardetails-center {
  width: 1293px;
  margin: 0 auto;
  position: relative;
  background-image: url("../public/images/pool/poolbg6.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.eraId-type2 .stardetails-center {
  background-image: url("../public/images/pool/poolbg2.png");
}

.eraId-type3 .stardetails-center {
  background-image: url("../public/images/pool/poolbg3.png");
}

.eraId-type4 .stardetails-center {
  background-image: url("../public/images/pool/poolbg4.png");
}

.eraId-type5 .stardetails-center {
  background-image: url("../public/images/pool/poolbg5.png");
}

.eraId-type6 .stardetails-center {
  background-image: url("../public/images/pool/poolbg1.png");
}

.eraId-type7 .stardetails-center {
  background-image: url("../public/images/pool/poolbg7.png");
}

.stardetails-colse-box {
  position: absolute;
  top: 18px;
  left: 0;
  width: 44px;
  height: 44px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  cursor: pointer;
}

.stardetails-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.stardetails-content-le {
  margin-top: 146px;
  width: 368px;
  min-width: 368px;
}

.tardetails-content-le-title {
  font-family: "Nebula";
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 44px;
  letter-spacing: -2px;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 40px;
}

.tardetails-content-le-list-box {
  position: relative;
  flex-wrap: wrap;
  padding: 70px 17px;
  background: url("../public/images/pool/poollinebg6.png") center no-repeat;
  background-size: 100% 100%;
}
.pool-img {
  position: absolute;
  top: -158px;
  left: 299px;
  width: 560px;
  height: 560px;
  img {
    width: 560px;
    height: 560px;
  }
}

.tardetailsbg2 {
  background: url("../public/images/pool/poollinebg2.png") center no-repeat;
  background-size: 100% 100%;
}

.tardetailsbg3 {
  background: url("../public/images/pool/poollinebg3.png") center no-repeat;
  background-size: 100% 100%;
}

.tardetailsbg4 {
  background: url("../public/images/pool/poollinebg4.png") center no-repeat;
  background-size: 100% 100%;
}

.tardetailsbg5 {
  background: url("../public/images/pool/poollinebg5.png") center no-repeat;
  background-size: 100% 100%;
}

.tardetailsbg6 {
  background: url("../public/images/pool/poollinebg.png") center no-repeat;
  background-size: 100% 100%;
}

.tardetailsbg7 {
  background: url("../public/images/pool/poollinebg7.png") center no-repeat;
  background-size: 100% 100%;
}

.tardetails-content-le-list-le {
  margin-bottom: 24px;
  overflow: hidden;
}

.tardetails-content-le-list-le:nth-child(even) {
  width: 100px;
}

.tardetails-content-le-list-le:nth-child(odd) {
  width: 166px;
}

.tardetails-content-le-list-title {
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.8);
}

.tardetails-content-le-list-texts {
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-transform: capitalize;
  color: #ffffff;
  margin-top: 10px;
}

.stardetails-content-ce {
  display: none;
  width: 100%;
}

.stardetails-content-ce > img {
  width: 100%;
  height: auto;
  display: block;
}

.stardetails-content-ri {
  margin-top: 65px;
  width: 345px;
  min-width: 345px;
}

.stardetails-content-ri-title {
  font-family: "Nebula";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  text-transform: capitalize;
  color: #ffffff;
}

.stardetails-content-ri-list-box {
  margin-top: 20px;
  width: 100%;
  flex-wrap: wrap;
}

.stardetails-content-ri-list:nth-child(odd) {
  margin-right: 16px;
}

.stardetails-content-ri-list {
  min-width: 164px;
  height: 56px;
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 61px;
  text-align: center;
  text-transform: capitalize;
  color: #ffe279;
  overflow: hidden;
  margin-bottom: 11px;
  cursor: pointer;
  background-image: url("../public/images/star/num-6.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.eraId-type2 .stardetails-content-ri-list {
  color: #4d892d;
  background-image: url("../public/images/star/num-2.png");
}

.eraId-type3 .stardetails-content-ri-list {
  color: #9d9370;
  background-image: url("../public/images/star/num-3.png");
}

.eraId-type4 .stardetails-content-ri-list {
  color: #ba6b21;
  background-image: url("../public/images/star/num-4.png");
}

.eraId-type5 .stardetails-content-ri-list {
  color: #2b61ac;
  background-image: url("../public/images/star/num-5.png");
}

.eraId-type6 .stardetails-content-ri-list {
  color: #ba3dbd;
  background-image: url("../public/images/star/num-1.png");
}

.eraId-type7 .stardetails-content-ri-list {
  color: #04779c;
  background-image: url("../public/images/star/num-7.png");
}

.stardetails-content-ri-list-acetive {
  color: #ffe279;
  background-image: url("../public/images/star/num-on-6.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.eraId-type2 .stardetails-content-ri-list-acetive {
  color: #5dcd21;
  background-image: url("../public/images/star/num-on-2.png");
}

.eraId-type3 .stardetails-content-ri-list-acetive {
  color: #ffeba5;
  background-image: url("../public/images/star/num-on-3.png");
}

.eraId-type4 .stardetails-content-ri-list-acetive {
  color: #ff7b00;
  background-image: url("../public/images/star/num-on-4.png");
}

.eraId-type5 .stardetails-content-ri-list-acetive {
  color: #096ffd;
  background-image: url("../public/images/star/num-on-5.png");
}

.eraId-type6 .stardetails-content-ri-list-acetive {
  color: #ef60f2;
  background-image: url("../public/images/star/num-on-1.png");
}

.eraId-type7 .stardetails-content-ri-list-acetive {
  color: #00c2ff;
  background-image: url("../public/images/star/num-on-7.png");
}

.stardetails-content-ri-price {
  margin-top: 24px;
  width: 100%;
  height: 107px;
  background: rgba(17, 0, 0, 0.16);
  // backdrop-filter: blur(4px);
  padding: 24px;
}

.stardetails-tips {
  padding: 9px 0;
  font-size: 12px;
  color: #ffffff;
}

.el-dropdown {
  border: 0 !important;
}

.el-dropdown-link {
  width: 345px;
  padding: 12px 0;
  background: rgba(0, 0, 0, 0.2);
  border: 0 !important;

  .icon-name {
    position: relative;
    padding: 0 16px;
    font-size: 16px;
    color: #fff;
    line-height: 24px;
    cursor: pointer;

    img {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      border-radius: 50%;
      object-fit: cover;
    }

    p {
      position: absolute;
      top: 0;
      right: 34px;
      font-size: 16px;
      color: #fff;
      line-height: 24px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 9px;
      right: 16px;
      width: 8px;
      height: 6px;
      background: url("../public/images/loginicon1.png") center no-repeat;
      background-size: 100% 100%;
    }
  }
}

.el-dropdown-menu {
  width: 345px;
  background-color: #4c4426 !important;
}

.stardetails-price {
  margin-top: 9px;
  justify-content: space-between;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);

  span {
    color: #fff;
  }
}

.coin-info {
  position: relative;
  display: flex;
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  cursor: pointer;
  width: 100%;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
    object-fit: cover;
  }

  p {
    position: absolute;
    top: 0;
    right: 16px;
    font-size: 16px;
    color: #bbbbbb;
  }
}

// .icon-name{
//     width: 345px;
//     padding: 12px 0;
//     background: rgba(0, 0, 0, 0.4);
// }

.stardetails-content-ri-price-le-title {
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.8);
}

.stardetails-content-ri-price-le-calc {
  margin-top: 14px;
}

.stardetails-content-ri-price-sub,
.stardetails-content-ri-price-add {
  border: 1px solid #555555;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.price rect {
  opacity: 1;
}

.stardetails-content-ri-price-viw {
  border-top: 1px solid #555555;
  border-bottom: 1px solid #555555;
  width: 50px;
  height: 30px;
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
}

.stardetails-content-ri-price-viw input {
  font-size: 16px;
  color: #fff;
  width: 48px;
  height: 28px;
  text-align: center;
}

.stardetails-content-ri-price-ce {
  width: 1px;
  height: 44px;
  background: rgba(255, 255, 255, 0.2);
  margin: 0 17px 0 32px;
}

.stardetails-content-ri-price-ri {
  flex: 1;
}

.stardetails-content-ri-price-ri-sum {
  height: 30px;
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #ffffff;
  margin-top: 14px;
}

.stardetails-content-ri-but {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 188px;
  height: 60px;
  background-image: url("../public/images/star/bt-6.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  font-family: "Nebula";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
}

.eraId-type2 .stardetails-content-ri-but {
  background-image: url("../public/images/star/bt-2.png");
}

.eraId-type3 .stardetails-content-ri-but {
  background-image: url("../public/images/star/bt-3.png");
}

.eraId-type4 .stardetails-content-ri-but {
  background-image: url("../public/images/star/bt-4.png");
}

.eraId-type5 .stardetails-content-ri-but {
  background-image: url("../public/images/star/bt-5.png");
}

.eraId-type6 .stardetails-content-ri-but {
  background-image: url("../public/images/star/bt-1.png");
}

.eraId-type7 .stardetails-content-ri-but {
  background-image: url("../public/images/star/bt-7.png");
}

.stardetails-content-ri-but-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: "Nebula";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 50px;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
}

.plaent-box {
  position: absolute;
  top: 52px;
  left: 50%;
  transform: translateX(-50%);
  width: 106px;
  height: 112px;
  background-image: url("../public/images/star/yang-6.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.eraId-type2 .plaent-box {
  background-image: url("../public/images/star/yang-2.png");
}

.eraId-type3 .plaent-box {
  background-image: url("../public/images/star/yang-3.png");
}

.eraId-type4 .plaent-box {
  background-image: url("../public/images/star/yang-4.png");
}

.eraId-type5 .plaent-box {
  background-image: url("../public/images/star/yang-5.png");
}

.eraId-type6 .plaent-box {
  background-image: url("../public/images/star/yang-1.png");
}

.eraId-type7 .plaent-box {
  background-image: url("../public/images/star/yang-7.png");
}

/* css动画 */
@media (min-width: 1200px) {
  /* 1\css 动画从左侧进入 */
  .fade-into-left {
    animation: fade-into-left 1s forwards;
  }

  @keyframes fade-into-left {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }

    to {
      opacity: 1;
      transform: translateX(21.5px);
    }
  }

  /* 1\css 动画从左侧出去 */
  .fade-out-left {
    animation: fade-out-left 1s forwards;
  }

  @keyframes fade-out-left {
    from {
      opacity: 1;
      transform: translateX(0px);
    }

    to {
      opacity: 0;
      transform: translateX(-50px);
    }
  }

  /* 2\css 动画从右侧进入 */
  .fade-into-ri {
    animation: fade-into-ri 1s forwards;
  }

  @keyframes fade-into-ri {
    from {
      opacity: 0;
      transform: translateX(50px);
    }

    to {
      opacity: 1;
      transform: translateX(-32px);
    }
  }

  /* 2\css 动画从右侧出去 */
  .fade-out-ri {
    animation: fade-out-ri 1s forwards;
  }

  @keyframes fade-out-ri {
    from {
      opacity: 1;
      transform: translateX(0px);
    }

    to {
      opacity: 0;
      transform: translateX(50px);
    }
  }

  /* 3\css 中间img 进入动画 */
  .fade-into-img {
    animation: fade-into-img 1s forwards;
  }

  @keyframes fade-into-img {
    from {
      opacity: 0;
      transform: scale(0);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  /* 3\css 中间img 出去动画 */
  .fade-out-img {
    animation: fade-out-img 1s forwards;
  }

  @keyframes fade-out-img {
    from {
      opacity: 1;
      transform: scale(1);
    }

    to {
      opacity: 0;
      transform: scale(0);
    }
  }

  /* 4\css 顶部星球 进入动画 */
  .fade-into-star {
    animation: fade-into-star 1s forwards;
  }

  @keyframes fade-into-star {
    from {
      opacity: 0;
      top: -100px;
      left: 155px;
      transform: scale(0);
    }

    to {
      opacity: 1;
      top: 52px;
      left: 555px;
      transform: scale(1);
    }
  }

  /* 4\css 顶部星球 出去动画 */
  .fade-out-star {
    animation: fade-out-star 1s forwards;
  }

  @keyframes fade-out-star {
    from {
      opacity: 1;
      top: 0;
      left: 275px;
      transform: scale(1);
    }

    to {
      opacity: 0;
      top: 200px;
      left: 155px;
      transform: scale(0);
    }
  }

  /* 5\css 背景 进入动画 */
  .fade-into-back {
    animation: fade-into-back 0.8s forwards;
  }

  @keyframes fade-into-back {
    from {
      opacity: 0;
      /* transform: scale(0); */
    }

    to {
      opacity: 1;
      /* transform: scale(1); */
    }
  }

  /* 5\css 背景 出去动画 */
  .fade-out-back {
    animation: fade-out-back 0.8s forwards;
  }

  @keyframes fade-out-back {
    from {
      opacity: 1;
      /* transform: scale(1); */
    }

    to {
      opacity: 0;
      /* transform: scale(0); */
    }
  }
}

/* 适配 */
@media (max-width: 1200px) {
  .stardetails-main-box {
    overflow-y: auto;
    top: 0;
    padding-top: 0;
    z-index: 9999;
  }

  .stardetails-bg {
    width: auto;
    height: auto;
    padding-top: 0;
    background-color: inherit !important;
    background-image: inherit !important;
  }

  .eraId-type1 {
    background: url("../public/images/star/h5/bg-1.png");
    background-size: cover;
  }

  .eraId-type2 {
    background: url("../public/images/star/h5/bg-2.png");
    background-size: cover;
  }

  .eraId-type3 {
    background: url("../public/images/star/h5/bg-3.png");
    background-size: cover;
  }

  .eraId-type4 {
    background: url("../public/images/star/h5/bg-4.png");
    background-size: cover;
  }

  .eraId-type5 {
    background: url("../public/images/star/h5/bg-5.png");
    background-size: cover;
  }

  .eraId-type6 {
    background: url("../public/images/star/h5/bg-6.png");
    background-size: cover;
  }

  .eraId-type7 {
    background: url("../public/images/star/h5/bg-7.png");
    background-size: cover;
  }

  .stardetails-center {
    width: 100%;
    margin: 0;
    margin-top: 24px;
    height: auto;
    background: transparent !important;
  }

  .stardetails-colse-box {
    top: 26px;
    right: 16px;
    width: 44px;
    height: 44px;
    margin-left: auto;
  }

  .plaent-box {
    top: 19px;
    left: 16px;
    width: 62px;
    height: 62px;
    transform: none;
  }

  .stardetails-content {
    display: block;
    height: auto;
    padding: 0 16px;
  }

  .stardetails-content-ce {
    display: block;
    padding-bottom: 100%;
    height: 0;
  }

  .stardetails-content-le {
    width: 100%;
    margin-top: 0;
    min-width: 100%;
  }

  .tardetails-content-le-title {
    font-size: 24px;
    margin-bottom: 26px;
  }

  .tardetails-content-le-list-box {
    padding: 0;
    margin-bottom: 16px;
    background: none !important;
  }
  .pool-img {
    display: none;
  }
  .tardetails-content-le-list-texts {
    font-size: 20px;
  }

  .tardetails-content-le-list-le:nth-child(even) {
    width: 33.33%;
  }

  .tardetails-content-le-list-le:nth-child(odd) {
    width: 33.33%;
  }

  .stardetails-content-ri {
    margin-top: 0;
    min-width: 100%;
  }

  .stardetails-content-ri {
    width: 100%;
  }

  .stardetails-content-ri-title {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .stardetails-content-ri-list-box {
    margin-top: 0;
    justify-content: space-between;
  }

  .stardetails-content-ri-list {
    width: 164px;
    height: 56px;
    margin: 0 0 8px 0;

    &:nth-child(odd) {
      margin-right: 0;
    }
  }

  .stardetails-content-ri-price {
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
    background: rgba(110, 110, 110, 0.16);
  }

  .stardetails-center .stardetails-content-ri-but {
    margin: 46px auto 16px;
    position: inherit;
    bottom: inherit;
    background-image: url("../public/images/star/h5/bt.png");
    width: 345px;
    height: 54px;
  }
}

@media (max-width: 767px) {
  .mining {
    width: auto;
  }

  .buy-success {
    width: 90%;
  }

  .el-dropdown {
    width: calc(100vw - 8px);
  }

  .stardetails-price {
    margin-bottom: 10px;
  }

  .el-dropdown-link {
    margin-top: 10px;
    width: 100% !important;
  }

  .el-dropdown-menu {
    width: calc(100vw - 8px) !important;
  }
}
</style>
